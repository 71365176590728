<template>
  <div class="tabs">
    <div
      class="item"
      :class="{ active: index === tabIndex }"
      v-for="(item, index) of tabs"
      :key="item.id"
      @click="handleItemClick(index)"
    >
      {{ item.name }}
    </div>
  </div>
</template>
<script>
export default {
  name: "Tabs",
  data() {
    return {
      tabIndex: 0,
      tabs: [
        {
          name: "售后处理",
        },
        {
          name: "售后列表",
        },
      ],
    };
  },
  created() {
    this.$emit("index", this.tabIndex);
  },
  methods: {
    handleItemClick(index) {
      this.tabIndex = index;
      this.$emit("index", this.tabIndex);
    },
  },
};
</script>
<style lang="less" scoped>
.tabs {
  display: flex;
  background-color: #f5f7fa;
  border-bottom: 1px solid #e4e7ed;
  .item {
    padding: 0 20px;
    line-height: 40px;
    box-sizing: border-box;
    border: 1px solid transparent;
    margin-top: -1px;
    color: #909399;
    cursor: pointer;
    overflow: hidden;
    &.active {
      background-color: #fff;
      border-right-color: #dcdfe6;
      border-left-color: #dcdfe6;
    }
  }
}
</style>