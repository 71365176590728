<template>
  <div class="nav">
    <el-row :gutter="20">
      <el-col :span="7">
        <el-date-picker
          v-model="date"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="5">
        <el-input
          placeholder="请输入订单号"
          v-model="searchVal"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearch"
          ></el-button>
        </el-input>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import AllSelect from "@/components/AllSelect";
import { dateShortcuts, toDaytime } from "@/util/shortcuts";
export default {
  name: "Nav",
  components: { AllSelect },
  data() {
    return {
      searchVal: "",
      date: toDaytime,
      startTime: "",
      endTime: "",
      pickerOptions: {
        shortcuts: dateShortcuts,
      },
    };
  },
  watch: {
    date(e) {
      if (e) {
        this.startTime = Math.floor(e[0] / 1000);
        this.endTime = Math.floor(e[1] / 1000);
        this.handleChange();
      }
    },
  },
  methods: {
    //选择时间
    handleChange() {
      this.$emit("change", {
        startTime: this.startTime,
        endTime: this.endTime,
      });
    },
    //搜索
    handleSearch() {
      this.$emit("search", this.searchVal);
    },
  },
};
</script>
<style lang="less" scoped>
</style>