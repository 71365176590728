<template>
  <table-container :list="list">
    <el-table-column type="index"> </el-table-column>
    <el-table-column prop="name" label="材质"> </el-table-column>
    <el-table-column prop="name" label="型号"> </el-table-column>
    <el-table-column prop="name" label="买家昵称"> </el-table-column>
    <el-table-column prop="name" label="订单号"> </el-table-column>
    <el-table-column prop="name" label="快递公司"> </el-table-column>
    <el-table-column prop="mcode" label="订单图片"> </el-table-column>
    <el-table-column prop="mcode" label="客服人员"> </el-table-column>
    <el-table-column prop="mcode" label="店铺"> </el-table-column>
    <el-table-column prop="mcode" label="状态"> </el-table-column>
    <el-table-column prop="mcode" label="备注"> </el-table-column>
    <el-table-column prop="mcode" label="提交时间"> </el-table-column>
    <!-- <template></template> -->
    <el-table-column prop="mcode" label="操作" v-if="tabIndex === 0">
      <template slot-scope="scope">
        <el-button type="danger" @click="handleRefund(scope.row)"
          >退款</el-button
        >
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
export default {
  name: "List",
  props: {
    list: Array,
    tabIndex: Number,
  },
  data() {
    return {};
  },
  methods: {
    handleRefund(item) {},
  },
};
</script>
<style lang="less" scoped>
</style>