<template>
  <div class="aftermarket">
    <aftermarket-tabs @index="handleTabIndexChange" />
    <el-row class="nav-container">
      <aftermarket-nav v-if="tabIndex === 1" />
      <el-col :span="5" v-else>
        <el-input
          placeholder="请输入订单号"
          v-model="ordersn"
          class="input-with-select"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleSearchOrderList"
          ></el-button>
        </el-input>
      </el-col>
    </el-row>
    <list :list="list" :tabIndex="tabIndex" />
  </div>
</template>
<script>
import AftermarketTabs from "./components/Tabs";
import AftermarketNav from "./components/Nav";
import List from "./components/List";
export default {
  name: "Aftermarket",
  components: {
    AftermarketTabs,
    AftermarketNav,
    List,
  },
  data() {
    return {
      ordersn: "",
      list: [],
      tabIndex: 0,
    };
  },
  methods: {
    //搜索订单
    async handleSearchOrderList() {
      const res = await this.$store.dispatch("getRefundOrderItems", {
        ordersn: this.ordersn,
      });
      console.log(res);
    },
    handleTabIndexChange(index) {
      this.tabIndex = index;
    },
    //获取售后列表
    getAftermarketList() {},
    getOrderList() {},
  },
};
</script>
<style lang="less" scoped>
.nav-container {
  margin-top: 20px;
}
</style>

